import * as React from "react"
import { Helmet} from 'react-helmet';


import config from "../utils/config";

//styles
import "../styles/css/styles.css"




// components
import Header from "../components/Header"
import Footer from "../components/Footer"
import defaultsData from '../utils/formStructure';
import Form from "../components/shared/Form";
import Search from "../components/Search/index";

import Contact from "../components/Contact";

export default function Contato() {

  return(
    <div>
      <Helmet>
          <title>Formulário de Garantia Wega</title>
          <link rel="shortcut icon" href={config.favicon} type="image/x-icon"></link>
      </Helmet>
        <Header />
        <Search />
        <div className="main-form">
            {/* <div className="container contato-title" style={{marginBottom: '260px'}}>
              <h1>Estamos em Manutenção</h1>
            </div> */}
            <div className="container contato-title">
                <h1>Formulário de Garantia Wega</h1>
            </div>
            <div className="main-form-infos">
                <h4>Somente emitir a nota fiscal, após a instrução do departamento de sac da Wega</h4>
                <p>CFOP 5202 devolução dentro do estado de Santa Catarina</p>
                <p>CFOP 5411 devolução com regime ST dentro do estado de Santa Catarina</p>
                <p>CFOP 6202 devolução fora do estado de Santa Catarina</p>
                <p>CFOP 6411 devolução com regime ST fora do estado de Santa Catarina</p>
                <h2 className="main-form-infos-title">Preencha todos os campos do formulário abaixo:</h2>
            </div>
            <section className="contato-custom formulario-garantia">
                <Form type="Formulário de Garantia Wega" structure={defaultsData.FGW} />
                <div className="procedimentos">
                    <h3>Nota: Procedimento de Garantia Wega Motors</h3>
                    <p>1) Recebimento 2) Analise Técnica 3) Retorno das Informações.</p>
                </div>
            </section>
        </div>
        <Footer />
    </div>
  )
}

export const Head = () => <title>Wega Motors</title>